import { Component } from '@angular/core';

@Component({
    template: `
    <h1 mat-dialog-title>Server Maintenance</h1>
    <div mat-dialog-content>
      Use of Sympliact is currently suspended due to scheduled maintenance.
      <br />
      We apologize for the inconvenience.
    </div>
  `,
    standalone: false
})
export class ServerMaintenanceDialogComponent {}
